// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("D:\\Clients\\SPKTR\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("D:\\Clients\\SPKTR\\src\\pages\\mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\Clients\\SPKTR\\.cache\\data.json")

